import {TEAM_SERVICE_URL} from "@atg-shared/service-url";
import type {PlaceBetCoupon} from "@atg-sport-shared/pool-types/couponTypes";
import {fetchAuthorized} from "@atg-shared/auth";
import type * as Big9Types from "@atg-sport-shared/pool-types/apiTypes/betTypes";
import type {Bet} from "@atg-sport-shared/pool-types/betTypes";

export type TillsammansBetBody = {
    coupons: ReadonlyArray<{
        nominalStake: number;
        selections: Big9Types.ApiSelectionMap;
        mode: string;
    }>;
    reference: string;
    stake: number;
    couponId: string;
    canceledLegs: string[];
};

export type SportBetBody = TillsammansBetBody | Big9Types.HarryBetBody;

export const placeBet = ({
    gameId,
    teamId,
    betBody,
}: {
    gameId: string;
    teamId: number;
    betBody: SportBetBody | PlaceBetCoupon;
}) =>
    fetchAuthorized<Bet>(`${TEAM_SERVICE_URL}/${teamId}/game/${gameId}/place-bet/`, {
        method: "POST",
        body: JSON.stringify(betBody),
    });

export const placeHarrySportBet = ({
    teamId,
    gameId,
    betBody,
}: {
    teamId: number;
    gameId: string;
    betBody: unknown;
}) =>
    fetchAuthorized<Bet>(
        `${TEAM_SERVICE_URL}/${teamId}/game/${gameId}/generate-and-place-bet/`,
        {
            method: "POST",
            body: JSON.stringify(betBody),
        },
    );
