import type {MatchComment} from "@atg-sport-shared/pool-types/matchTypes";
import type {Offering} from "@atg-sport-shared/pool-types/offeringTypes";
import type {
    LeagueStatistics,
    MatchStatistics,
    TeamStatistics,
} from "@atg-sport-shared/pool-types/statisticsTypes";

export type SportGame = Offering;

export type SportMatchStatistics = {
    matches: Record<string, MatchStatistics>;
    leagues: Record<string, LeagueStatistics>;
    teams: Record<string, TeamStatistics>;
};

export const EMPTY_MATCH_STATISTICS: Readonly<SportMatchStatistics> = {
    matches: {},
    leagues: {},
    teams: {},
};

export type SportMatchComments = {
    matchComments: Record<string, MatchComment>;
};

export const EMPTY_MATCH_COMMENTS: Readonly<SportMatchComments> = {
    matchComments: {},
};

export type SportStatistics = SportMatchStatistics & SportMatchComments;
