import type {
    Offering,
    Pool as OfferingPool,
} from "@atg-sport-shared/pool-types/offeringTypes";
import type {GameType} from "@atg-tillsammans-shared/types";
import type {HorseGame} from "../horse/horseGameTypes";
import type {Game, GamePool, GameSummary} from "./gameTypes";
import {isBig9Payouts} from "@atg-sport-shared/pool-util-bets-helpers";

export const isSportGame = (gameId: string): boolean => gameId.includes("BIG9");
export const getGameType = (gameId: string): GameType => gameId.split("_")[0] as GameType;
export const getGameDate = (gameId: string): string => gameId.split("_")[1];

export const mapHorseGameToGameSummary = (game: HorseGame): GameSummary => ({
    id: game.id,
    type: getGameType(game.id),
    pool: Object.values(game.pools ?? []).reduce<GamePool | undefined>(
        (acc, pool) =>
            pool.id !== game.id
                ? acc
                : {
                      timestamp: pool.timestamp,
                      turnover: pool.turnover,
                      systemCount: pool.systemCount,
                      payouts: pool.payouts,
                  },
        undefined,
    ),
});

export const mapSportGameToGameSummary = (offering: Offering): GameSummary => {
    function getPayouts(pool: OfferingPool): {[key: string]: number} | undefined {
        return pool.payouts && isBig9Payouts(pool.payouts)
            ? {
                  9: pool.payouts["9"].pot,
                  8: pool.payouts["8"].pot,
                  7: pool.payouts["7"].pot,
              }
            : undefined;
    }

    return {
        id: offering.id,
        type: getGameType(offering.id),
        pool: {
            timestamp: offering.pool.timestamp,
            turnover: offering.pool.turnover,
            systemCount: Math.round(offering.pool.numberOfSystems),
            payouts: getPayouts(offering.pool),
        },
    };
};

export const mapGameToGameSummary = (game: Game): GameSummary =>
    isSportGame(game.id)
        ? mapSportGameToGameSummary(game as Offering)
        : mapHorseGameToGameSummary(game as HorseGame);
