import type {SagaIterator} from "redux-saga";
import {all, call, put, select, takeLatest} from "redux-saga/effects";
import {find} from "lodash";
import {type Coupon, CouponTypes} from "@atg-horse-shared/coupon-types";
import {AuthSelectors} from "@atg-shared/auth";
import {parseDateTimestamp} from "@atg-shared/datetime";
import type {BetTransaction, TechnicalChannel} from "@atg-horse-shared/bet-history-types";
import type {PrivateShareParticipant} from "@atg-tillsammans-shared/shared-bet-types";
import {
    createTransactionInfoResponse,
    getSharedBetName,
    toShareHolder,
} from "../utils/sharedBetMappers";
import * as SharedBetApi from "./sharedBetApi";
import {
    SharedBetActions,
    STARTED_COUPONS_FETCH,
    type StartedCouponsFetchAction,
} from "./sharedBet.actions";

async function getParticipation(
    transaction: BetTransaction,
): Promise<{ok: boolean; participation: PrivateShareParticipant}> {
    const participation = createTransactionInfoResponse(transaction);
    if (transaction.sourceSystem !== "HBH") {
        const receipt = await SharedBetApi.fecthReceipt(
            transaction.id,
            transaction.sourceSystem,
        );
        const name = receipt.data?.shareInfo?.name ?? "";
        return {
            ok: receipt.ok,
            participation: {
                ...participation,
                sharedBet: {
                    ...participation.sharedBet,
                    name: getSharedBetName({
                        name,
                    }),
                    initiator: toShareHolder(name),
                },
                numberOfShares: receipt.data?.shareInfo?.nrOfShares?.my ?? 0,
            },
        };
    }
    const nonVarenneReceiptId = `${parseDateTimestamp(
        transaction.placedAt.toString(),
    ).format("YYYY-MM-DD")}_${transaction.id}`;
    const nonVarenneReceipt = await SharedBetApi.fecthNonVarenneReceipt(
        nonVarenneReceiptId,
    );
    const name = nonVarenneReceipt.data?.shareInfo?.initiator ?? "";
    return {
        ok: nonVarenneReceipt.ok,
        participation: {
            ...participation,
            sharedBet: {
                ...participation.sharedBet,
                name: getSharedBetName({
                    name,
                }),
                initiator: toShareHolder(name),
            },
            numberOfShares: nonVarenneReceipt.data?.shareInfo?.ownedNrShares ?? 0,
        },
    };
}

/**
 * @param action: StartedCouponsFetchAction
 * @returns
 */
export function* fetchStartedCoupons({
    payload: {timestamp},
}: StartedCouponsFetchAction): SagaIterator {
    const isLoggedIn = yield select(AuthSelectors.isLoggedIn);

    if (!isLoggedIn) return;

    try {
        const {data} = yield call(SharedBetApi.fetchStartedCoupons);

        const coupons = ((data?.data ?? []) as Coupon[]).filter(
            (coupon) =>
                coupon.type === CouponTypes.PRIVATE_TEAM ||
                coupon.type === CouponTypes.PRIVATE_TEAM_REDUCED,
        );

        const dateTime = parseDateTimestamp(timestamp);

        const toDate = dateTime.format("YYYY-MM-DD");

        const transactionsResult = yield call(SharedBetApi.fetchTransactions, {
            toDate,
            fromDate: dateTime.subtract(7, "days").format("YYYY-MM-DD"),
            timestamp,
        });

        const transactions = (
            (transactionsResult?.data?.content ?? []) as BetTransaction[]
        )
            .filter(
                (betTransaction) =>
                    (betTransaction.technicalChannel as string) ===
                        ("INTERNET_ANDELSSPEL" as TechnicalChannel) &&
                    betTransaction.raceDate >= toDate,
            )
            .reduce<{
                coupons: BetTransaction[];
                receipts: BetTransaction[];
            }>(
                (acc, t) => {
                    if (t.isPreBetShare) {
                        return {
                            ...acc,
                            coupons: [...acc.coupons, t],
                        };
                    }
                    return {
                        ...acc,
                        receipts: [...acc.receipts, t],
                    };
                },
                {coupons: [], receipts: []},
            );

        const payload: Array<PrivateShareParticipant> = transactions.coupons.map(
            (transaction) => {
                const participation = createTransactionInfoResponse(transaction);
                const coupon = find(
                    coupons,
                    (c) =>
                        c.teamId === participation.teamId &&
                        c.game.id.toUpperCase() === participation.game.id.toUpperCase(),
                );
                return {
                    ...participation,
                    couponId: coupon?.id,
                };
            },
        );

        if (transactions.receipts.length > 0) {
            const transactionReceipts = yield all(
                transactions.receipts.map((transaction) =>
                    call(getParticipation, transaction),
                ),
            );

            (
                transactionReceipts as {
                    ok: boolean;
                    participation: PrivateShareParticipant;
                }[]
            ).forEach(({ok, participation}) => {
                if (ok) payload.push(participation);
            });
        }

        yield put(
            SharedBetActions.fetchStartedSuccess(
                payload.reduce((acc, item) => ({...acc, [item.id]: item}), {}),
            ),
        );
    } catch (error: unknown) {
        yield put(SharedBetActions.fetchStartedError(error));
    }
}

export default function* sharedBetSaga() {
    yield takeLatest(STARTED_COUPONS_FETCH, fetchStartedCoupons);
}
