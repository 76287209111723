import * as imageHandlerUtils from "./imageHandlerUtils";
import * as imageUtils from "./imageUtils";

export {CloudfrontHelper} from "./cloudfront.helper";
export {ImageSrc} from "./imageSrc.builder";

export const ImageUtils = {
    ...imageHandlerUtils,
    ...imageUtils,
};
