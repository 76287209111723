import * as React from "react";
import type {SerializedStyles} from "@emotion/react";
import type {
    ImageRef,
    ImageUrl,
    ResponsiveImageProps,
    BorderProps,
} from "@atg-tillsammans-shared/image-types";
import {ASPECT_RATIO_1_1} from "@atg-tillsammans-shared/image-types";
import {CloudfrontHelper, ImageUtils} from "@atg-tillsammans-shared/image-utils";
import {AvatarImage} from "../AvatarImage";
import avatar from "../../assets/images/neutralAvatar.svg";

export type Props = {
    style?: SerializedStyles;
    imageUrl: ImageUrl | null | undefined;
    size?: number;
    border?: boolean;
    borderOptions?: BorderProps;
    rounded?: boolean;
    shadow?: boolean;
    alt?: string;
    showTooltip?: boolean;
} & ResponsiveImageProps;

const toUrl = (imageRef: ImageRef): ImageUrl | null => {
    const basePath = `${CloudfrontHelper.getImagesCloudfrontUrl()}/members`;
    if (["0", "1", "male.png", "female.png"].includes(imageRef)) return null;
    return `${basePath}/${imageRef}`;
};

const getDefaultSrc = (imageUrl: ImageUrl | null, size: number, isMale?: boolean) => {
    if (isMale || imageUrl === "1") {
        return `${CloudfrontHelper.getImagesCloudfrontUrl()}/${size}x${size}/members/male.png`;
    }
    return `${CloudfrontHelper.getImagesCloudfrontUrl()}/${size}x${size}/members/female.png`;
};

export function MemberAvatar({
    style,
    imageUrl,
    size = 90,
    xs,
    sm,
    md,
    lg,
    border = false,
    borderOptions,
    rounded = false,
    shadow = false,
    alt,
    showTooltip = false,
}: Props) {
    const imgRef = imageUrl ? ImageUtils.getImageRef(imageUrl) : imageUrl;
    const src = imgRef ? toUrl(imgRef) : null;
    return (
        <AvatarImage
            style={style}
            imageUrl={src}
            alt={alt}
            size={size}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            border={border}
            borderOptions={borderOptions}
            rounded={rounded}
            shadow={shadow}
            background="none"
            defaultImageSrc={avatar}
            showTooltip={showTooltip}
        />
    );
}

export const mapUrl = (imageUrl: ImageUrl | null, size: number, isMale?: boolean) => {
    const {getSrc} = ImageUtils.forAspectRatio(ASPECT_RATIO_1_1);
    return imageUrl && ImageUtils.isValidUrl(imageUrl)
        ? getSrc({imageUrl, width: size})
        : getDefaultSrc(imageUrl, size, isMale);
};
