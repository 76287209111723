import root from "window-or-global";
import Features, {newImageHandler} from "@atg-shared/client-features";
import type {ImagesConfig} from "@atg-tillsammans-shared/image-types";

const getImagesCloudfrontUrl = (
    useNewImageHandler: boolean = Features.isEnabled(newImageHandler),
): string => {
    const {imagesCloudfrontUrl, imagesCloudfrontUrlV2} = (
        root.clientConfig as unknown as ImagesConfig
    ).aws;
    return useNewImageHandler && imagesCloudfrontUrlV2
        ? imagesCloudfrontUrlV2
        : imagesCloudfrontUrl;
};

const getStaticImagesCloudfrontUrl = (): string =>
    (root.clientConfig as unknown as ImagesConfig).aws.staticImagesCloudfrontUrl;

export const CloudfrontHelper = {getImagesCloudfrontUrl, getStaticImagesCloudfrontUrl};
