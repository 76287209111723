import type {
    ImageSize,
    ImageProps,
    ImageUrl,
    ImageRef,
    ImageAspectRatio,
} from "@atg-tillsammans-shared/image-types";
import * as ImageHandlerUtils from "./imageHandlerUtils";

const resolvAspectRatio = (width: number, aspectRatio: ImageAspectRatio): ImageSize => ({
    width,
    height: Math.ceil(width * aspectRatio.heightMultiplicand),
});

const getResolutions = (params: ImageProps, maxDensity = 2): string | undefined => {
    const {imageUrl, width = 0, height = 0} = params;
    if (!ImageHandlerUtils.isValidUrl(imageUrl) || (width === 0 && height === 0))
        return undefined;
    const densities = Array.from({length: maxDensity}, (_, i) => i + 1);
    return densities
        .map(
            (density) =>
                `${ImageHandlerUtils.size({
                    imageUrl,
                    width: width * density,
                    height: height * density,
                })} ${density}x`,
        )
        .join(",");
};

const forAspectRatio = (aspectRatio: ImageAspectRatio, maxDensity = 2) => ({
    getSrcSet: ({imageUrl, width = 0}: ImageProps) =>
        getResolutions({imageUrl, ...resolvAspectRatio(width, aspectRatio)}, maxDensity),
    getSrc: ({imageUrl, width = 0}: ImageProps) =>
        ImageHandlerUtils.size({imageUrl, ...resolvAspectRatio(width, aspectRatio)}),
});

const forFixedHeight = (height: number, maxDensity = 2) => ({
    getSrcSet: ({imageUrl, width = 0}: ImageProps) =>
        getResolutions({imageUrl, width, height}, maxDensity),
    getSrc: ({imageUrl, width = 0}: ImageProps) =>
        ImageHandlerUtils.size({imageUrl, width, height}),
});

const getImageRef = (imageUrl: ImageUrl | string): ImageRef =>
    imageUrl.replace(/^.*[\\/]/, "");

export {resolvAspectRatio, getResolutions, forAspectRatio, forFixedHeight, getImageRef};
