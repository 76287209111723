import * as React from "react";
import {useTheme} from "@atg-ui/toolkit/theme";
import type {
    ImageUrl,
    ResponsiveImageProps,
    BorderProps,
    ImageAspectRatio,
} from "@atg-tillsammans-shared/image-types";
import {ImageUtils} from "@atg-tillsammans-shared/image-utils";
import {Image} from "../Image";
import {ImageContainer} from "./ResponsiveImage.styles";

type Props = {
    imageUrl: ImageUrl | null;
    width: number;
    heightRule?: ImageAspectRatio | number;
    border?: boolean;
    borderOptions?: BorderProps;
    rounded?: boolean;
    alt?: string;
    type?: string;
    showTooltip?: boolean;
    renderDefaultImage: () => React.ReactNode;
    renderOverlay?: () => React.ReactNode;
} & ResponsiveImageProps;

export function ResponsiveImage({
    imageUrl,
    width,
    heightRule,
    xs,
    sm,
    md,
    lg,
    border = false,
    borderOptions,
    rounded = false,
    type,
    alt,
    showTooltip = false,
    renderDefaultImage,
    renderOverlay,
}: Props) {
    const {breakpoints} = useTheme();
    const [imageBroken, setImageBroken] = React.useState(false);

    let getSrc = ImageUtils.size;
    let getSrcSet = ImageUtils.getResolutions;
    let wrapperHeight;

    if (heightRule) {
        if (typeof heightRule === "number") {
            getSrc = ImageUtils.forFixedHeight(heightRule).getSrc;
            getSrcSet = ImageUtils.forFixedHeight(heightRule).getSrcSet;
            wrapperHeight = `${heightRule}px`;
        } else {
            getSrc = ImageUtils.forAspectRatio(heightRule).getSrc;
            getSrcSet = ImageUtils.forAspectRatio(heightRule).getSrcSet;
            wrapperHeight = `${heightRule.heightMultiplicand * 100}%`;
        }
    }

    const handleError = () => {
        setImageBroken(true);
    };

    if (imageBroken) {
        return (
            <ImageContainer height={wrapperHeight}>
                {renderOverlay && renderOverlay()}
                {renderDefaultImage()}
            </ImageContainer>
        );
    }

    let img;
    if (imageUrl) {
        if (ImageUtils.isValidUrl(imageUrl, false)) {
            img = (
                <picture>
                    {lg && (
                        <source
                            media={`(min-width: ${breakpoints.values.lg}px)`}
                            srcSet={getSrcSet({imageUrl, width: lg})}
                        />
                    )}
                    {md && (
                        <source
                            media={`(min-width: ${breakpoints.values.md}px)`}
                            srcSet={getSrcSet({imageUrl, width: md})}
                        />
                    )}
                    {sm && (
                        <source
                            media={`(min-width: ${breakpoints.values.sm}px)`}
                            srcSet={getSrcSet({imageUrl, width: sm})}
                        />
                    )}
                    {xs ? (
                        <source srcSet={getSrcSet({imageUrl, width: xs})} />
                    ) : (
                        <source srcSet={getSrcSet({imageUrl, width})} />
                    )}
                    <Image
                        src={getSrc({imageUrl, width})}
                        type={type}
                        alt={alt}
                        border={border}
                        borderOptions={borderOptions}
                        rounded={rounded}
                        wrapped={!!heightRule}
                        showTooltip={showTooltip}
                        onError={handleError}
                    />
                </picture>
            );
        } else if (ImageUtils.isAllowedImage(imageUrl)) {
            img = (
                <Image
                    src={imageUrl}
                    type={type}
                    alt={alt}
                    border={border}
                    borderOptions={borderOptions}
                    rounded={rounded}
                    wrapped={!!heightRule}
                    showTooltip={showTooltip}
                    onError={handleError}
                />
            );
        }
    }
    return (
        <ImageContainer height={wrapperHeight}>
            {renderOverlay && renderOverlay()}
            {img || renderDefaultImage()}
        </ImageContainer>
    );
}
