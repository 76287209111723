import type {Action} from "redux";
import {runLazySaga} from "@atg-shared/lazy-store";
import type {LazyStore} from "@atg-shared/lazy-store";
import Features, {tillsammansPush} from "@atg-shared/client-features";
import {fetchSaga} from "@atg-shared/fetch-redux";
import {roundSaga} from "@atg-tillsammans/round-data-access";
import {sharedBetSaga} from "@atg-tillsammans-shared/shared-bet-data-access";
import {teamSaga} from "@atg-tillsammans/team-data-access/redux/team";
import {inviteSaga} from "@atg-tillsammans/team-data-access/redux/invite";
import gameSaga from "@atg-tillsammans/game/common/redux/gameSaga";
import {personalizationSaga} from "@atg-tillsammans-shared/personalization-data-access";
import chatSaga from "@atg-tillsammans/chat/domain/teamChatSaga";
import tillsammansPushSaga from "@atg-tillsammans/push/domain/tillsammansPushSaga";
import {
    appSaga,
    memberCustomizationSaga,
    trackingSaga,
} from "@atg-tillsammans/app-data-access/redux";
import sportBetSaga from "@atg-tillsammans/bet-common/sport/redux/sportBetSaga";
import {callAuthSaga} from "@atg-tillsammans/auth";
import toastSaga from "atg-ui-toast/domain/toastSaga";

export default function rootSaga(store: LazyStore<unknown, Action>) {
    runLazySaga(store, appSaga);
    runLazySaga(store, memberCustomizationSaga);
    runLazySaga(store, fetchSaga);
    runLazySaga(store, teamSaga);
    runLazySaga(store, inviteSaga);
    runLazySaga(store, sportBetSaga);
    runLazySaga(store, personalizationSaga);
    runLazySaga(store, toastSaga);
    runLazySaga(store, gameSaga, store);
    runLazySaga(store, chatSaga, store.dispatch);
    if (Features.isEnabled(tillsammansPush))
        runLazySaga(store, tillsammansPushSaga, store.dispatch);
    runLazySaga(store, callAuthSaga);
    runLazySaga(store, trackingSaga);
    runLazySaga(store, roundSaga);
    runLazySaga(store, sharedBetSaga);
}
