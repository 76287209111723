import {t} from "@lingui/macro";
import {type TeamID, TeamInviteStatus} from "@atg-tillsammans/types";

export const parseTeamId = (inviteCode: string): TeamID =>
    inviteCode.substring(inviteCode.lastIndexOf("-") + 1);

export const parseTeamInviteStatus = (status: string): TeamInviteStatus => {
    switch (status) {
        case TeamInviteStatus.JOINED:
        case TeamInviteStatus.QUEUED:
        case TeamInviteStatus.ALREADY_QUEUED:
        case TeamInviteStatus.TO_MANY_TEAMS:
        case TeamInviteStatus.INVALID_INVITE:
        case TeamInviteStatus.ALREADY_MEMBER:
        case TeamInviteStatus.TECHNICAL_ERROR:
            return status;
        default:
            return TeamInviteStatus.TECHNICAL_ERROR;
    }
};

export const getMessages = (status: TeamInviteStatus): string => {
    switch (status) {
        case TeamInviteStatus.JOINED:
            return t({
                id: "team.toast.message.JOINED",
                message:
                    "Du är nu medlem i laget. Missa inte att köpa en andel för att delta i omgångens spel...",
            });
        case TeamInviteStatus.QUEUED:
            return t({
                id: "team.toast.message.QUEUED",
                message:
                    "Laget är tyvärr fullt just nu men du är placerad i kö och blir medlem när en plats blir ledig.",
            });
        case TeamInviteStatus.ALREADY_QUEUED:
            return t({
                id: "team.toast.message.ALREADY_QUEUED",
                message:
                    "Du är redan placerad i kö och blir medlem när en plats blir ledig.",
            });
        case TeamInviteStatus.TO_MANY_TEAMS:
            return t({
                id: "team.toast.message.TO_MANY_TEAMS",
                message:
                    "Attans! Du kan inte gå med i fler lag eftersom du nått maxgränsen för antal lag per person. För att gå med i det här laget behöver du först gå ur ett annat lag och sen klicka på inbjudningslänken igen.",
            });
        case TeamInviteStatus.INVALID_INVITE:
            return t({
                id: "team.toast.message.INVALID_INVITE",
                message: "Inbjudningskoden var tyvärr ogiltig.",
            });
        case TeamInviteStatus.ALREADY_MEMBER:
            return t({
                id: "team.toast.message.ALREADY_MEMBER",
                message:
                    "Hoppsan! Du verkar ha använt en inbjudningslänk till ett lag du redan var medlem i.",
            });
        case TeamInviteStatus.TECHNICAL_ERROR:
        default:
            return t`Tekniken strular just nu, men vi jobbar på en lösning. Testa igen snart!`;
    }
};
