import type {Reducer} from "redux";
import {combineReducers} from "redux";
import {MatchClockStatus} from "@atg-sport-shared/pool-types/matchTypes";
import type {GameReducerState} from "../../common/gameTypes";
import * as SportGameActions from "./sportGameActions";

type SportGameReducerState = GameReducerState["sport"]["games"];
type SportStatisticsReducerState = GameReducerState["sport"]["statistics"];
type SportMatchClockReducerState = GameReducerState["sport"]["matchClocks"];

const games: Reducer<SportGameReducerState, SportGameActions.Action> = (
    state: SportGameReducerState = {},
    action: SportGameActions.Action,
) => {
    switch (action.type) {
        case SportGameActions.SPORT_GAME_FETCH_RESPONSE:
            return action.error || !action.payload.game
                ? state
                : {
                      ...state,
                      [action.payload.game.id]: action.payload.game,
                  };
        case SportGameActions.SPORT_GAME_UPDATE:
            return {
                ...state,
                [action.payload.game.id]: action.payload.game,
            };
        case SportGameActions.SPORT_GAME_MATCH_UPDATE:
            return Object.values(state).reduce<SportGameReducerState>(
                (updatedState, game) => {
                    const matches = game.matches.map((match) => {
                        if (match.id === action.payload.match.id) {
                            return match?.lastUpdated &&
                                action.payload.match?.lastUpdated &&
                                match?.lastUpdated > action.payload.match?.lastUpdated
                                ? match
                                : {...match, ...action.payload.match};
                        }
                        return match;
                    });

                    return {
                        ...updatedState,
                        [game.id]: {
                            ...game,
                            matches,
                        },
                    };
                },
                {},
            );
        default:
            return state;
    }
};

const statistics: Reducer<SportStatisticsReducerState, SportGameActions.Action> = (
    state: SportStatisticsReducerState = {
        matches: {},
        leagues: {},
        teams: {},
        matchComments: {},
    },
    action: SportGameActions.Action,
) => {
    switch (action.type) {
        case SportGameActions.SPORT_MATCH_STATISTICS_FETCH_RESPONSE:
            return {
                ...state,
                matches: {
                    ...state.matches,
                    ...action.payload.matches,
                },
                leagues: {
                    ...state.leagues,
                    ...action.payload.leagues,
                },
                teams: {
                    ...state.teams,
                    ...action.payload.teams,
                },
            };
        case SportGameActions.SPORT_MATCH_COMMENTS_FETCH_RESPONSE:
            return {
                ...state,
                matchComments: {
                    ...state.matchComments,
                    ...action.payload.matchComments,
                },
            };
        default:
            return state;
    }
};

const matchClocks: Reducer<SportMatchClockReducerState, SportGameActions.Action> = (
    state: SportMatchClockReducerState = {},
    action: SportGameActions.Action,
) => {
    switch (action.type) {
        case SportGameActions.SPORT_GAME_MATCH_CLOCK_START: {
            const {matchId, minute = 0} = action.payload;
            return {
                ...state,
                [action.payload.matchId]: {
                    ...state[matchId],
                    minute,
                    status: MatchClockStatus.Ongoing,
                    period: minute > 0 ? 2 : 1,
                },
            };
        }
        case SportGameActions.SPORT_GAME_MATCH_CLOCK_UPDATE: {
            const {matchId, minute} = action.payload;
            return {
                ...state,
                [matchId]: {
                    ...state[matchId],
                    minute,
                },
            };
        }
        case SportGameActions.SPORT_GAME_MATCH_CLOCK_STOP: {
            const {matchId} = action.payload;
            return {
                ...state,
                [matchId]: {
                    ...state[matchId],
                    status: MatchClockStatus.Stopped,
                },
            };
        }
        case SportGameActions.SPORT_GAME_MATCH_CLOCK_ADD: {
            const {matchId, minute} = action.payload;
            return {
                ...state,
                [matchId]: {
                    ...state[matchId],
                    minute,
                    period: 1,
                    status: MatchClockStatus.Stopped,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({games, statistics, matchClocks});
