/* eslint-disable no-underscore-dangle */
import {combineReducers} from "redux";
import {storeApi} from "@atg-horse-shared/store-api";
import {teamsReducer as teams} from "@atg-tillsammans/team-data-access/redux/team";
import {inviteReducer as invite} from "@atg-tillsammans/team-data-access/redux/invite";
import {jackpotReducer as jackpot} from "@atg-tillsammans-shared/jackpot";
import game from "@atg-tillsammans/game/common/redux/gameReducer";
import bet from "@atg-tillsammans/bet-common/common/redux/betReducer";
import chat from "@atg-tillsammans/chat/domain/teamChatReducer";
import {roundReducer as round} from "@atg-tillsammans/round-data-access";
import {sharedBetReducer as sharedBet} from "@atg-tillsammans-shared/shared-bet-data-access";
import {personalizationReducer as personalization} from "@atg-tillsammans-shared/personalization-data-access";
import {sagaFlowReducer as sagaFlow} from "@atg-tillsammans-shared/saga-flow";
import {memberCustomizationReducer as memberCustomization} from "@atg-tillsammans/app-data-access/redux";
import {modals, modalData} from "atg-modals/modalReducer";

const createRootReducer = () =>
    combineReducers({
        [storeApi.reducerPath]: storeApi.reducer,
        memberCustomization,
        teams,
        jackpot,
        game,
        bet,
        personalization,
        mainMenu: () => window._frameStore.getState().mainMenu,
        modals,
        modalData,
        chat,
        sagaFlow,
        invite,
        round,
        sharedBet,
        alert: () => window._frameStore.getState().alert,
        unAuthorizedLogin: () => window._frameStore.getState().unAuthorizedLogin,
        auth: () => window._frameStore.getState().auth,
        accessToken: () => window._frameStore.getState().accessToken,
        router: () => window._frameStore.getState().router,
        user: () => window._frameStore.getState().user,
        limits: () => window._frameStore.getState().limits,
        toasts: () => window._frameStore.getState().toasts,
        mainNavbar: () => window._frameStore.getState().mainNavbar,
    });

export default createRootReducer;
