import {combineReducers, type Reducer} from "redux";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {FetchReducer} from "@atg-shared/fetch-types";
import type {InviteAction as Action, GetInviteAction} from "./invite.actions";
import {
    ACCEPT_INVITE_ACTION,
    FETCH_INVITE_INFO_REQUEST,
    FETCH_INVITE_INFO_RESPONSE,
    REQUEST_INVITE_LINK,
    RECEIVE_INVITE_LINK,
    RESET_INVITE_LINK,
    ACCEPT_INVITE_RESPONSE,
    ACCEPT_INVITE_RESET,
} from "./invite.actions";
import type {InviteInfo, InviteLinkState, InviteState, InviteStatus} from "./inviteTypes";

export const inviteCode = (state = "", action: Action): string => {
    switch (action.type) {
        case ACCEPT_INVITE_ACTION:
        case FETCH_INVITE_INFO_REQUEST:
            return action.payload.inviteCode;
        default:
            return state;
    }
};

export const inviteStatus = (state = {}, action: Action): InviteStatus => {
    switch (action.type) {
        case ACCEPT_INVITE_RESPONSE:
            return {
                status: action.payload.status,
                error: action.error,
            };
        case ACCEPT_INVITE_RESET:
            return {};
        default:
            return state;
    }
};

export const inviteInfo = (
    state: InviteInfo = {loaded: false},
    action: Action,
): InviteInfo => {
    switch (action.type) {
        case FETCH_INVITE_INFO_RESPONSE:
            if (action.error) {
                return {loaded: true};
            }
            return {
                loaded: true,
                invite: action.payload.invite,
            };
        default:
            return state;
    }
};

const inviteLink: FetchReducer<InviteLinkState, GetInviteAction> = createFetchReducer(
    REQUEST_INVITE_LINK,
    RECEIVE_INVITE_LINK,
    RESET_INVITE_LINK,
    (state: InviteLinkState, action: GetInviteAction) => {
        switch (action.type) {
            case RECEIVE_INVITE_LINK:
                if (action.error) return state;
                return {
                    ...state,
                    data: action.payload,
                };
            default:
                return state;
        }
    },
    {data: undefined},
);

const invite: Reducer<InviteState, Action> = combineReducers({
    inviteCode,
    inviteInfo,
    inviteLink,
    inviteStatus,
});

export default invite;
